/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './src/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  corePlugins: {
    preflight: false,
  },
  theme: {
    extend: {},
    colors: {
      primary: 'var(--PRIMARY_COLOR)',
      'primary-50': 'var(--PRIMARY_50)',
      'primary-100': 'var(--PRIMARY_100)',
      'primary-200': 'var(--PRIMARY_200)',
      'primary-300': 'var(--PRIMARY_300)',
      'primary-400': 'var(--PRIMARY_400)',
      'primary-500': 'var(--PRIMARY_500)',
      'primary-600': 'var(--PRIMARY_600)',
      'primary-700': 'var(--PRIMARY_700)',
      'primary-800': 'var(--PRIMARY_800)',
      'primary-900': 'var(--PRIMARY_900)',
      secondary: 'var(--SECONDARY_COLOR)',
      'secondary-50': 'var(--SECONDARY_50)',
      'secondary-100': 'var(--SECONDARY_100)',
      'secondary-200': 'var(--SECONDARY_200)',
      'secondary-300': 'var(--SECONDARY_300)',
      'secondary-400': 'var(--SECONDARY_400)',
      'secondary-500': 'var(--SECONDARY_500)',
      'secondary-600': 'var(--SECONDARY_600)',
      'secondary-700': 'var(--SECONDARY_700)',
      'secondary-800': 'var(--SECONDARY_800)',
      'secondary-900': 'var(--SECONDARY_900)',
      orange: 'var(--ORANGE_COLOR)',
      gray: 'var(--GRAY_COLOR)',
      'gray-50': 'var(--GRAY_50)',
      'gray-200': 'var(--GRAY_200)',
      white: 'var(--WHITE_COLOR)',
      'green-400': 'var(--GREEN_400)',
      'blue-500': 'var(--BLUE_500)',
      'gray-400': 'var(--GRAY_400)',

    },
  },
  plugins: [],
  darkMode: 'media',
  variants: {
    extend: {},
  },
};
