import {catchError, filter, map, mergeMap, retry} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {of} from 'rxjs';
import {
  ADD_AUTH_USER_PROFILE_TO_LOCAL_STORAGE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCESSFUL,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_START,
  IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
  LOGIN_SUCCESSFUL,
  LOGOUT_START,
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_SUCCESSFUL,
} from '../actionConstants';
import {
  addUserProfileToLocalStorage,
  doNothing,
  getUserProfileFail,
  getUserProfileStart,
  getUserProfileSuccessful,
  identifyAuthExistOnDeviceFail,
  identifyAuthExistOnDeviceSuccessful,
  loginFail,
  logoutStart,
  logoutSuccessful,
  updateUserProfileFail,
  updateUserProfileSuccessful,
} from '../actions';
import {addLocalAuth, addUserProfileToLocalAuth, getLocalAuth} from '../../localStorage';
import {getUserProfileAPI, logoutUser, updateUserProfileAPI} from './apis';

export const loginSuccessfulEpic = action$ =>
  action$.pipe(
    filter(
      action =>
        action.type === LOGIN_SUCCESSFUL ||
        action.type === IDENTIFY_AUTH_EXIST_ON_DEVICE_SUCCESSFUL,
    ),
    mergeMap(userResponseData =>
      addLocalAuth({...userResponseData?.payload})
        .then(auth => getUserProfileStart(auth))
        .catch(logoutStart),
    ),
    catchError(error => of(loginFail(error.response))),
  );

export const identifyAuthExistOnDeviceStartEpic = action$ =>
  action$.pipe(
    ofType(IDENTIFY_AUTH_EXIST_ON_DEVICE_START),
    mergeMap(action =>
      getLocalAuth()
        .then(auth => {
          if(!auth) {
            return identifyAuthExistOnDeviceFail();
          }
          return identifyAuthExistOnDeviceSuccessful(auth);
        })
        .catch(error => identifyAuthExistOnDeviceFail()),
    ),
  );

export const getUserProfileStartEpic = action$ =>
  action$.pipe(
    ofType(GET_USER_PROFILE_START),
    mergeMap(action =>
      getUserProfileAPI(action.payload).pipe(
        retry(0),
        map(response => getUserProfileSuccessful(response.response)),
        catchError(error => of(getUserProfileFail(action.payload))),
      ),
    ),
  );

export const getUserProfileFailEpic = action$ =>
  action$.pipe(
    filter(action => action.type === GET_USER_PROFILE_FAIL),
    map(action => logoutStart(action.payload)),
  );

export const updateUserProfileStartEpic = action$ =>
  action$.pipe(
    ofType(UPDATE_USER_PROFILE_START),
    mergeMap(action =>
      updateUserProfileAPI(action.payload).pipe(
        retry(0),
        map(() => updateUserProfileSuccessful(action.payload)),
        catchError(error => of(updateUserProfileFail(error.response))),
      ),
    ),
  );

export const updateUserProfileSuccessfulEpic = action$ =>
  action$.pipe(
    filter(
      action =>
        action.type === GET_USER_PROFILE_SUCCESSFUL ||
        action.type === UPDATE_USER_PROFILE_SUCCESSFUL,
    ),
    map(action => addUserProfileToLocalStorage(action.payload)),
  );

export const addUserProfileToLocalStorageEpic = action$ =>
  action$.pipe(
    ofType(ADD_AUTH_USER_PROFILE_TO_LOCAL_STORAGE),
    mergeMap(action =>
      addUserProfileToLocalAuth(action.payload)
        .then(doNothing)
        .catch(doNothing),
    ),
  );

export const logoutStartEpic = action$ =>
  action$.pipe(
    ofType(LOGOUT_START),
    mergeMap(action =>
      logoutUser(action.payload).pipe(
        retry(0),
        map(logoutSuccessful),
        catchError(error => of(logoutSuccessful())),
      ),
    ),
  );
