import {rqCreateRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_GET_PAGEABLE_ALL_PROJECT_REPORT_API,
  RQ_GET_PROJECT_REPORT_BY_ID_API,
  RQ_GET_PROJECT_REPORT_PHOTO_FOR_ID_API,
  RQ_PUT_UPLOAD_PHOTO_FOR_PROJECT_REPORT_API,
  RQ_REGISTER_PROJECT_REPORT_API,
  RQ_UPDATE_FIELDS_BY_PROJECT_REPORT_API,
} from '@app/reactQuery/apis/constants';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';

export const RQPostProjectReportRegister = async ({
  projectReport,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REGISTER_PROJECT_REPORT_API,
    JSON.stringify({...projectReport}),
    tokenData?.access_token,
    null,
    signal,
  );
};

export const RQUseGetProjectReportByIdData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_project_report_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PROJECT_REPORT_BY_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 0,
    ...options,
  },
  );
};

export const RQUseGetPageableAllProjectReportData = (payload, options = {}) => {
  return useInfiniteQuery({
    queryKey: ['rq_get_pageable_all_project_report', payload],
    queryFn: async ({queryKey, signal, pageParam = 0}) => {
      const {tokenData, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_PAGEABLE_ALL_PROJECT_REPORT_API,
        null,
        tokenData?.access_token,
        {pageParam},
        signal,
        'application/json',
        'application/x-ndjson',
        filter,
      );
    },
    initialPageParam: 0,
    refetchInterval: 30 * 1000,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    ...options,
  });
};

export const RQPutProjectReportUpdate = async ({
  projectReport,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_FIELDS_BY_PROJECT_REPORT_API,
    JSON.stringify({...projectReport}),
    tokenData?.access_token,
    {id: projectReport.id},
    signal,
  );
};

export const RQPutUploadPhoto = async ({
  projectReportId,
  projectReportPhoto,
  tokenData,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_UPLOAD_PHOTO_FOR_PROJECT_REPORT_API,
    JSON.stringify({...projectReportPhoto}),
    tokenData?.access_token,
    {projectReportId: projectReportId},
    signal,
  );
};

export const RQUseGetProjectReportForPhotoByIdData = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_get_project_report_for_photo_by_id', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_PROJECT_REPORT_PHOTO_FOR_ID_API,
        null,
        tokenData?.access_token,
        {id},
        signal,
      );
    },
    gcTime: 0,
    ...options,
  },
  );
};
